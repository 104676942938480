<template>
  <section class="InfoNovelties" v-if="item">
    <div class="InfoNovelties__container" @click="changeValue">
      <div class="InfoNovelties__item-content">{{ getTipesNovedades(item.type) }}</div>
      <div class="InfoNovelties__item-time">
        <iconic name="clock_solid"></iconic>
        <span>{{ changeTime(item.average) }}</span>
      </div>
    </div>
    <div class="InfoNovelties__description" v-if="showDetail">
      <div class="break" v-if="getTipesNovedades(item.type) === 'Break' && new Date(item.dateCreate) > dateStartDay && item.status !== 'approved'">
        <label class="InfoNovelties__time">Actualizar tiempo</label>
        <input class="input__break" ginput type="number" v-model="selectedHourBreak" @input="validateHourBreak" /> :
        <input class="input__break" ginput type="number" v-model="selectedMinBreak" @input="validateMinBreak" />
      </div>

      <div class="InfoNovelties__setHoursSelect" v-if="new Date(item.dateCreate) > dateStartDay && item.status !== 'approved'">
        <div v-if="item.type === '627aca008e84d13c1f2dee94'">
          <div class="date__transmision" v-if="item.type === '627aca008e84d13c1f2dee94'">
            <label class="title__transmision">Inicio de transmision</label>
            <div class="container_date">
              <input type="date" id="fecha" name="fecha" class="calendar" v-model="dateInit" :max="maxDate" />
              <input ginput class="date" type="number" v-model="selectedHourInit" @input="validateHour('selectedHourInit', 12)" /> :
              <input ginput class="date" type="number" v-model="selectedMinInit" @input="validateNumber('selectedMinInit')" />
              <select class="options" v-model="optionTime">
                <option value="am">AM</option>
                <option value="pm">PM</option>
              </select>
            </div>
          </div>
          <div class="date__transmision" v-if="item.type === '627aca008e84d13c1f2dee94'">
            <label class="title__transmision">Fin de transmision</label>
            <div class="container_date">
              <input type="date" id="fecha" name="fecha" class="calendar" v-model="dateEnd" :min="minDate" :max="maxDate" />
              <input ginput class="date" type="number" v-model="selectedHourEnd" @input="validateHour('selectedHourEnd', 12)" /> :
              <input ginput class="date" type="number" v-model="selectedMinEnd" @input="validateNumber('selectedMinEnd')" />
              <select class="options" v-model="optionTimeEnd">
                <option value="am">AM</option>
                <option value="pm">PM</option>
              </select>
            </div>
          </div>
        </div>
        <div class="others__novelties">
          <div v-if="item.type != '628cee2c87c76adae90cf84d' && item.type != '627aca008e84d13c1f2dee94'">
            <label class="InfoNovelties__setHour-label" for="Desde">Desde:</label>
            <input ginput class="date" type="number" v-model="hourInit" @input="validateHour('hourInit', 12)" /> :
            <input ginput class="date" type="number" v-model="minInit" @input="validateNumber('minInit')" />
            <select class="options" v-model="optionTime">
              <option value="am">AM</option>
              <option value="pm">PM</option>
            </select>
          </div>
          <div v-if="item.type != '628cee2c87c76adae90cf84d' && item.type != '627aca008e84d13c1f2dee94'">
            <label for="Hasta">Hasta:</label>
            <input ginput class="date" type="number" v-model="hourEnd" @input="validateHour('hourEnd', 12)" /> :
            <input ginput class="date" type="number" v-model="minEnd" @input="validateNumber('minEnd')" />
            <select class="options" v-model="optionTimeEnd">
              <option value="am">AM</option>
              <option value="pm">PM</option>
            </select>
          </div>
        </div>
        <textarea :disabled="new Date(item.dateCreate) < dateStartDay" class="InfoNovelties__desctiptionArea" v-model="description"></textarea>
        <button class="InfoNovelties__setHoursSelect-update" @click="updateCase">
          Actualizar
        </button>
      </div>
    </div>
  </section>
</template>

<script>
  import mixinCrudNovelties from "./mixinCrudNovelties";
  import Swal from "sweetalert2";
  export default {
    props: ["items"],
    mixins: [mixinCrudNovelties],
    data() {
      return {
        item: null,
        showDetail: false,
        optionHours: [],
        description: "",
        dateStartDay: new Date(new Date().setHours(0, 0, 0, 0)),
        selectedHourBreak: 0,
        selectedMinBreak: 0,

        selectedHourInit: 0,
        selectedMinInit: "00",
        selectedHourEnd: 0,
        selectedMinEnd: "00",
        optionTime: "am",
        optionTimeEnd: "pm",
        dateInit: "",
        dateEnd: "",

        hourEnd: "",
        minEnd: "",
        hourInit: "",
        minInit: "",
      };
    },
    computed: {
      maxDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      },
      minDate() {
        return this.getCurrentDate();
      },
      initTransmision() {
        const dateString = `${this.selectedHourInit}:${this.selectedMinInit}${this.optionTime}`;
        const timeParts = dateString.match(/(\d+):(\d+)(am|pm)/);
        const hour = parseInt(timeParts[1]);
        const minute = parseInt(timeParts[2]);
        const period = timeParts[3];
        const currentDate = new Date(this.dateInit);

        if (period === "pm" && hour !== 12) {
          currentDate.setHours(hour + 36);
        } else if ((period === "am" && hour < 12) || (period === "pm" && hour === 12)) {
          currentDate.setHours(hour + 24);
        } else {
          if (period === "am" && hour === 12) {
            currentDate.setHours(hour + 12);
          } else {
            currentDate.setHours(hour);
          }
        }
        currentDate.setMinutes(minute);
        return currentDate;
      },
      endTransmision() {
        const dateString = `${this.selectedHourEnd}:${this.selectedMinEnd}${this.optionTimeEnd}`;
        const timeParts = dateString.match(/(\d+):(\d+)(am|pm)/);
        const hour = parseInt(timeParts[1]);
        const minute = parseInt(timeParts[2]);
        const period = timeParts[3];
        const currentDate = new Date();
        if (period === "pm" && hour !== 12) {
          currentDate.setHours(hour + 12);
        } else if (period === "am" && hour === 12) {
          currentDate.setHours(0);
        } else {
          currentDate.setHours(hour);
        }
        currentDate.setMinutes(minute);

        return currentDate;
      },
      endHour() {
        const dateString = `${this.hourEnd}:${this.minEnd}${this.optionTimeEnd}`;
        const timeParts = dateString.match(/(\d+):(\d+)(am|pm)/);
        const hour = parseInt(timeParts[1]);
        const minute = parseInt(timeParts[2]);
        const period = timeParts[3];
        const currentDate = new Date();
        if (period === "pm" && hour !== 12) {
          currentDate.setHours(hour + 12);
        } else if (period === "am" && hour === 12) {
          currentDate.setHours(0);
        } else {
          currentDate.setHours(hour);
        }
        currentDate.setMinutes(minute);

        return currentDate;
      },
      startHour() {
        const dateString = `${this.hourInit}:${this.minInit}${this.optionTime}`;
        const timeParts = dateString.match(/(\d+):(\d+)(am|pm)/);
        const hour = parseInt(timeParts[1]);
        const minute = parseInt(timeParts[2]);
        const period = timeParts[3];
        const currentDate = new Date();
        if (period === "pm" && hour !== 12) {
          currentDate.setHours(hour + 12);
        } else if (period === "am" && hour === 12) {
          currentDate.setHours(0);
        } else {
          currentDate.setHours(hour);
        }
        currentDate.setMinutes(minute);

        return currentDate;
      },
    },
    methods: {
      changeValue() {
        return (this.showDetail = !this.showDetail);
      },
      convertDateMongo(data) {
        const fechaMongo = data;
        const fechaObjeto = new Date(fechaMongo);
        const offset = -5 * 60;
        fechaObjeto.setMinutes(fechaObjeto.getMinutes() + offset);
        const formatoDeseado = `${fechaObjeto.getFullYear()}-${(fechaObjeto.getMonth() + 1).toString().padStart(2, "0")}-${fechaObjeto
          .getDate()
          .toString()
          .padStart(2, "0")} ${fechaObjeto}`;

        return formatoDeseado;
      },
      convertirA12Horas(hora24) {
        if (hora24 === 0) {
          return "12";
        } else if (hora24 === 12) {
          return "12";
        } else if (hora24 < 12) {
          return "0" + hora24;
        } else {
          return hora24 - 12;
        }
      },
      validateHour(propertyName, maxValue) {
        let numero = parseInt(this[propertyName], 10);

        if (!isNaN(numero) && numero < 10) {
          this[propertyName] = "0" + numero;
        } else if (numero < 1) {
          this[propertyName] = 0;
        } else if (numero > maxValue) {
          this[propertyName] = maxValue;
        }
      },
      validateMinutes(min) {
        if (min < 10) {
          return "0" + min;
        } else {
          return min;
        }
      },
      validateNumber(propertyName) {
        let numero = parseInt(this[propertyName], 10);

        if (!isNaN(numero) && numero < 10) {
          this[propertyName] = "0" + numero;
        } else if (numero < 1) {
          this[propertyName] = 0;
        } else if (numero > 60) {
          this[propertyName] = 60;
        }
      },
      getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      },
      dataAverage() {
        return parseInt(this.selectedHourBreak) * 60 + parseInt(this.selectedMinBreak);
      },
      convertirMinutosAHoras(minutos) {
        const horas = Math.floor(minutos / 60);
        const minutosRestantes = minutos % 60;
        this.selectedHourBreak = horas;
        this.selectedMinBreak = minutosRestantes;
      },
      validateHourBreak() {
        if (this.selectedHourBreak < 1) {
          this.selectedHourBreak = 0;
        } else if (this.selectedHourBreak > 7) {
          this.selectedHourBreak = 7;
        }
      },
      validateMinBreak() {
        if (this.selectedMinBreak < 1) {
          this.selectedMinBreak = 0;
        } else if (this.selectedMinBreak > 60) {
          this.selectedMinBreak = 60;
        }
      },
      changeTime(numeroMinutos) {
        const horas = Math.floor(numeroMinutos / 60);
        const minutos = numeroMinutos % 60;

        const horasFormateadas = horas.toString().padStart(2, "0");
        const minutosFormateados = minutos.toString().padStart(2, "0");

        return `${horasFormateadas}:${minutosFormateados}`;
      },
      async updateCase() {
        this.isLoading = true;
        let body = {};
        if (this.item.type === "628cee2c87c76adae90cf84d") {
          body = {
            _id: this.item?._id,
            description: this.description,
            average: this.dataAverage(),
            initTransmision: this.item?.initTransmision,
            endTransmision: this.item?.endTransmision,
          };
        } else if (this.item.type === "627aca008e84d13c1f2dee94") {
          body = {
            _id: this.item?._id,
            description: this.description,
            initTransmision: this.initTransmision,
            endTransmision: this.endTransmision,
          };
        } else {
          body = {
            _id: this.item?._id,
            description: this.description,
            date: this.startHour,
            dateTo: this.endHour,
          };
        }

        const data = await this.updateNovelties(body);
        this.isLoading = false;
        this.item = data.update;
        this.showDetail = false;
        Swal.fire({
          title: "Completado",
          text: "Novedad actualizada exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
      },
    },
    beforeMount() {
      this.item = this.items;
      this.selectedHourInit = this.convertirA12Horas(new Date(this.item.initTransmision).getHours());
      this.selectedHourEnd = this.convertirA12Horas(new Date(this.item.endTransmision).getHours());
      this.selectedMinInit = this.validateMinutes(new Date(this.item.initTransmision).getMinutes());
      this.selectedMinEnd = this.validateMinutes(new Date(this.item.endTransmision).getMinutes());
      this.optionTime = new Date(this.item.initTransmision).getHours() <= 12 ? "am" : "pm";
      this.optionTimeEnd = new Date(this.item.endTransmision).getHours() <= 12 ? "am" : "pm";
      this.dateInit = this.convertDateMongo(this.item?.initTransmision).split(" ")[0];
      this.dateEnd = this.convertDateMongo(this.item?.endTransmision).split(" ")[0];
      this.description = this.items?.description;
      this.hourEnd = this.convertirA12Horas(new Date(this.item.dateTo).getHours());
      this.minEnd = this.validateMinutes(new Date(this.item.dateTo).getMinutes());
      this.hourInit = this.convertirA12Horas(new Date(this.item.date).getHours());
      this.minInit = this.validateMinutes(new Date(this.item.date).getMinutes());
    },
    mounted() {
      if (this.item.type === "628cee2c87c76adae90cf84d" && this.item.average <= 60) {
        this.selectedMinBreak = this.item.average;
      } else {
        this.convertirMinutosAHoras(this.item.average);
      }

      this.optionHours = this.optionsDay();
    },
  };
</script>

<style lang="scss">
  .InfoNovelties {
    &__time {
      margin-right: 15px;
    }
    &__description {
      .break {
        padding: 10px 5px;
        display: flex;
        .input__break {
          width: 60px;
          height: 25px;
          margin: 0 2px;
        }
      }
    }
    &__container {
      display: flex;
      justify-content: space-between;
      border-radius: 5px;
      padding: 5px;
      width: 100%;
    }
    &__desctiptionArea {
      width: 100%;
      padding: 5px;
      height: 100px;
      overflow-y: auto;
      border: none;
      outline: none;
    }
    &__setHoursSelect {
      display: grid;
      &-select {
        height: 30px;
        width: 110px;
        outline: none;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border: none;
      }
      &-update {
        margin: 10px auto;
        width: 50%;
        height: 30px;
        border-radius: 5px;
        background-color: $primary-color;
        border: none;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
      }
      .others__novelties {
        display: flex;
        gap: 10px;
      }
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
      border: solid 1px rgb(234, 234, 234);
      background-color: #f5f5f5;
      &:hover {
        background-color: #fff;
      }
      &-time {
        :nth-child(1) {
          position: relative;
          top: 1px;
          right: 1px;
        }
        .iconic {
          margin: 3px 2px;
        }
        border-radius: 5px;
        padding: 2px 5px;
        border: solid 1px $primary-color;
        color: $primary-color;
      }
      &-content {
        font-size: 14px;
      }
    }
  }
</style>
