<script>
  import mixinCrudNovelties from "./mixinCrudNovelties";
  import detailNoveltieUser from "./UserDetailnovelties.vue";
  import Swal from "sweetalert2";
  export default {
    name: "InfoNovelties",
    props: ["user", "day"],
    mixins: [mixinCrudNovelties],
    components: { detailNoveltieUser },
    data() {
      return {
        title: "",
        typeNovedad: "",
        description: "",
        showCreate: false,
        check: "",
        selectedHourInit: 0,
        selectedMinInit: "00",
        selectedHourEnd: 0,
        selectedMinEnd: "00",
        selectedHourBreak: 0,
        selectedMinBreak: "00",
        optionTime: "am",
        optionTimeEnd: "pm",
        dateInit: "",
        dateEnd: "",
        lastLoginMonitor: "",
      };
    },
    computed: {
      filteredNovietis() {
        return this.typesNovietis.filter((objeto) => !["Incapacidad", "Calamidad", "Tiempo de conexión", "Break"].includes(objeto.name));
      },
      endHour() {
        const dateString = `${this.selectedHourEnd}:${this.selectedMinEnd}${this.optionTimeEnd}`;
        const timeParts = dateString.match(/(\d+):(\d+)(am|pm)/);
        const hour = parseInt(timeParts[1]);
        const minute = parseInt(timeParts[2]);
        const period = timeParts[3];
        const currentDate = new Date();
        if (period === "pm" && hour !== 12) {
          currentDate.setHours(hour + 12);
        } else if (period === "am" && hour === 12) {
          currentDate.setHours(0);
        } else {
          currentDate.setHours(hour);
        }
        currentDate.setMinutes(minute);

        return currentDate;
      },
      startHour() {
        const dateString = `${this.selectedHourInit}:${this.selectedMinInit}${this.optionTime}`;
        const timeParts = dateString.match(/(\d+):(\d+)(am|pm)/);
        const hour = parseInt(timeParts[1]);
        const minute = parseInt(timeParts[2]);
        const period = timeParts[3];
        const currentDate = new Date();
        if (period === "pm" && hour !== 12) {
          currentDate.setHours(hour + 12);
        } else if (period === "am" && hour === 12) {
          currentDate.setHours(0);
        } else {
          currentDate.setHours(hour);
        }
        currentDate.setMinutes(minute);

        return currentDate;
      },
      maxDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      },
      dataAverage() {
        return parseInt(this.selectedHourBreak) * 60 + parseInt(this.selectedMinBreak);
      },
      minDate() {
        return this.getCurrentDate();
      },
      initTransmision() {
        const dateString = `${this.selectedHourInit}:${this.selectedMinInit}${this.optionTime}`;
        const timeParts = dateString.match(/(\d+):(\d+)(am|pm)/);
        const hour = parseInt(timeParts[1]);
        const minute = parseInt(timeParts[2]);
        const period = timeParts[3];
        const currentDate = new Date(this.dateInit);
        if (period === "pm" && hour !== 12) {
          currentDate.setHours(hour + 36);
        } else if ((period === "am" && hour < 12) || (period === "pm" && hour === 12)) {
          currentDate.setHours(hour + 24);
        } else {
          if (period === "am" && hour === 12) {
            currentDate.setHours(hour + 12);
          } else {
            currentDate.setHours(hour);
          }
        }
        currentDate.setMinutes(minute);
        return currentDate;
      },
      endTransmision() {
        const dateString = `${this.selectedHourEnd}:${this.selectedMinEnd}${this.optionTimeEnd}`;
        const timeParts = dateString.match(/(\d+):(\d+)(am|pm)/);
        const hour = parseInt(timeParts[1]);
        const minute = parseInt(timeParts[2]);
        const period = timeParts[3];
        const currentDate = new Date();
        if (period === "pm" && hour !== 12) {
          currentDate.setHours(hour + 12);
        } else if (period === "am" && hour === 12) {
          currentDate.setHours(0);
        } else {
          currentDate.setHours(hour);
        }
        currentDate.setMinutes(minute);

        return currentDate;
      },
      replenish() {
        const values = {
          "627aca008e84d13c1f2dee94": "No reponer",
          "627aca008e84d13c1f2dee95": "Reponer",
          "627aca478e84d13c1f2dee96": "Reponer",
          "627aca728e84d13c1f2dee97": "No reponer",
          "627aca9a8e84d13c1f2dee98": "No reponer",
          "627acaaf8e84d13c1f2dee99": "Reponer",
          "627aca008e84d13c1f2dee94": "No reponer",
          "627acac58e84d13c1f2dee9a": this.check,
        };
        return values[this.typeNovedad.$oid] || "";
      },
      showTime() {
        return this.typeNovedad.$oid === "627acac58e84d13c1f2dee9a" ? true : false;
      },
      validateInfoTosend() {
        if (this.typeNovedad && this.description && this.selectedHourInit && this.selectedHourEnd) {
          return true;
        }
        return false;
      },
      NoveltiesOfDay() {
        return this.user.novelties.filter((item) => new Date(item.dateCreate).getDay() === new Date(this.day).getDay());
      },
      validateDate() {
        const isValidHour = (hour) => hour !== 0;
        const isValidDate = (date) => date !== "";

        return (
          isValidHour(this.selectedHourInit) &&
          isValidHour(this.selectedMinInit) &&
          isValidHour(this.selectedHourEnd) &&
          isValidHour(this.selectedMinEnd) &&
          isValidHour(this.selectedMinBreak) &&
          isValidDate(this.dateInit) &&
          isValidDate(this.dateEnd)
        );
      },
      noveltieInitOrEndTransmision() {
        const today = new Date().toISOString().split("T")[0];

        return this.user.novelties.some((item) => {
          const itemDate = new Date(item.date).toISOString().split("T")[0];
          return item.type === "627aca008e84d13c1f2dee94" && itemDate === today;
        });
      },
    },
    methods: {
      getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      },
      validateHour(propertyName, maxValue) {
        let numero = parseInt(this[propertyName], 10);
        if (isNaN(numero) || this[propertyName] === "" || numero < 1) {
          numero = 0;
        } else if (numero > maxValue) {
          numero = maxValue;
        }
        this[propertyName] = numero.toString().padStart(2, "0");
      },
      changeFormatDate(value) {
        const fecha = new Date(value);
        const hora = fecha.getHours();
        const minutos = fecha.getMinutes();

        return hora + ":" + minutos;
      },
      validateNumber(propertyName) {
        let numero = parseInt(this[propertyName], 10);
        numero = isNaN(numero) || this[propertyName] === "" || numero < 1 ? 0 : numero;
        numero = Math.min(Math.max(numero, 0), 60);
        this[propertyName] = numero.toString().padStart(2, "0");
      },
      validateHourBreak() {
        if (this.selectedHourBreak < 1) {
          this.selectedHourBreak = 0;
        } else if (this.selectedHourBreak > 7) {
          this.selectedHourBreak = 7;
        }
      },
      validateMinBreak() {
        this.selectedMinBreak = this.selectedMinBreak.replace(/^0+/, "");
        if (this.selectedMinBreak < 1) {
          this.selectedMinBreak = "00";
        } else if (this.selectedMinBreak > 60) {
          this.selectedMinBreak = "60";
        } else if (this.selectedMinBreak < 10) {
          this.selectedMinBreak = "0" + this.selectedMinBreak;
        }
      },
      onAddValue(value) {
        this.showCreate = !this.showCreate;
        if (!this.showCreate) {
          this.resetCreate();
        }
      },
      async dataNovelties(query, data) {
        try {
          const novelties = await this.getNoveltiesAdmin(query);
          this.user.novelties = [];
          for (let index = 0; index < novelties.length; index++) {
            if (data.assignament === novelties[index].assignament._id) {
              this.user.novelties.push(novelties[index]);
            }
          }
        } catch (error) {
          console.error("Error al obtener las novedades", error.message);
        }
      },
      hourToCreateNoveltie(date, hour) {
        let newDate = new Date(date);
        newDate.setHours(newDate.getHours() + hour);
        return newDate;
      },
      async createNewNovelty() {
        const query = `date=${this.getCurrentDate()}&dateTo=${this.getCurrentDate()}`;
        if (this.validateDate) {
          this.typeNovedad = { name: "Tiempo de conexión", isEnable: true, $oid: "627aca008e84d13c1f2dee94" };
          this.replenish = "No reponer";
          this.description = `Observaciones:\nTotal de tiempo durante el cual la modelo estuvo conectada`;
          if (this.endTransmision <= this.initTransmision) {
            const errorMessage = "La fecha de finalización debe ser mayor que la fecha de inicio.";
            const errorElement = document.getElementById("error-message");
            errorElement.textContent = errorMessage;
            setTimeout(() => {
              errorElement.style.display = "none";
            }, 3000);
            this.resetCreate();
            return;
          }
          this.isLoading = true;
          const data = {
            type: this.typeNovedad.$oid,
            description: this.description,
            date: this.startHour,
            dateTo: this.endHour,
            assignament: this.user.modelId._id,
            city: this.user.modelId.city,
            office: this.user.modelId.office,
            room: this.user.modelId.room,
            replenish: this.replenish,
            initTransmision: this.initTransmision,
            endTransmision: this.endTransmision,
            average: this.dataAverage,
            status: "approved",
          };
          const response = await this.createNovelties(data);
          if (response.error) {
            this.isLoading = false;
            this.showModal = false;
            this.resetCreate();
            return;
          }
          this.isLoading = false;
          this.showCreate = false;
          this.resetCreate();
          data.type === "627aca008e84d13c1f2dee94" && this.dataNovelties(query, data);
        } else {
          this.isLoading = true;
          const data = {
            type: this.typeNovedad.$oid,
            description: this.description,
            date: this.startHour,
            dateTo: this.endHour,
            assignament: this.user.modelId._id,
            city: this.user.modelId.city,
            office: this.user.modelId.office,
            room: this.user.modelId.room,
            replenish: this.replenish,
            average: this.dataAverage,
          };

          const turnMonitor = this.hourToCreateNoveltie(this.lastLoginMonitor, 10);

          if (data.dateTo > turnMonitor) {
            Swal.fire({
              title: "Ups...",
              text: "En esas horas no tuviste esa modelo asignada",
              icon: "warning",
              confirmButtonText: "Aceptar",
            });
            return;
          }
          const response = await this.createNovelties(data);
          if (response.error) {
            this.isLoading = false;
            this.showModal = false;
            this.resetCreate();
            return;
          }
          this.isLoading = false;
          this.dataNovelties(query, data);
          this.showCreate = false;
          this.resetCreate();
        }
        const result = await Swal.fire({
          title: "Completado",
          text: "Novedad creada exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        if (result.isConfirmed) {
          window.location.reload();
        }
      },
      resetCreate() {
        this.typeNovedad = "";
        this.description = "";
        this.selectedHourInit = 0;
        this.selectedMinInit = "00";
        this.selectedHourEnd = 0;
        this.selectedMinEnd = "00";
        this.selectedHourBreak = 0;
        this.selectedMinBreak = 0;
        this.dateInit = "";
        this.dateEnd = "";
      },
    },
    mounted() {
      this.lastLoginMonitor = new Date(this.$userData.lastLogin);
      this.optionHours = this.optionsDay();
    },
  };
</script>
<template>
  <section class="InfoNovelties ">
    <section class="InfoNovelties__wrapper">
      <div class="line"></div>
      <div class="InfoNovelties__container">
        <div class="InfoNovelties__text">
          <avatar class="InfoNovelties__avatar" :user="user.modelId"></avatar>
          <div class="InfoNovelties__textInfo">
            <div class="InfoNovelties__textInfo--model">
              <p class="name">{{ user.modelId.user || user.modelId.artisticName }}</p>
              <p class="city">{{ user.modelId.city }}</p>
            </div>
            <p class="office">{{ user.modelId.office }}</p>
          </div>
        </div>
        <div>
          <button class="cardNovelties__button" v-if="validButtonActiveToAdd(day)" @click="onAddValue">
            <iconic :name="!showCreate ? 'plus' : 'close'"></iconic>
          </button>
        </div>
      </div>
      <div class="data__transmision" v-if="!showCreate && validButtonActiveToAdd(day)">
        <div class="InfoNovelties__info" v-if="!noveltieInitOrEndTransmision">
          <div class="date__transmision">
            <label class="title__transmision">Inicio de transmision</label>
            <div class="container_date">
              <input type="date" id="fecha" name="fecha" class="calendar" v-model="dateInit" :max="maxDate" />
              <input ginput class="date" type="number" v-model="selectedHourInit" @input="validateHour('selectedHourInit', 12)" /> :
              <input ginput class="date" type="number" v-model="selectedMinInit" @input="validateNumber('selectedMinInit')" />
              <select class="options" v-model="optionTime">
                <option value="am">AM</option>
                <option value="pm">PM</option>
              </select>
            </div>
          </div>
          <div class="date__transmision">
            <label class="title__transmision">Fin de transmision</label>
            <div class="container_date">
              <input type="date" id="fecha" name="fecha" class="calendar" v-model="dateEnd" :min="minDate" :max="maxDate" />
              <input ginput class="date" type="number" v-model="selectedHourEnd" @input="validateHour('selectedHourEnd', 12)" /> :
              <input ginput class="date" type="number" v-model="selectedMinEnd" @input="validateNumber('selectedMinEnd')" />
              <select class="options" v-model="optionTimeEnd">
                <option value="am">AM</option>
                <option value="pm">PM</option>
              </select>
            </div>
          </div>
          <div class="date__transmision">
            <label class="title__transmision">Break</label>
            <div class="break">
              <input class="input__break" ginput type="number" v-model="selectedHourBreak" @input="validateHourBreak" /> :
              <input class="input__break" ginput type="number" v-model="selectedMinBreak" @input="validateMinBreak" />
            </div>
          </div>
        </div>
        <div id="error-message" class="error-message"></div>
        <section class="InfoNovelties__items" v-if="user.novelties.length">
          <div class="InfoNovelties__item " v-for="(item, idx) in NoveltiesOfDay" :key="idx">
            <detailNoveltieUser :items="item"></detailNoveltieUser>
          </div>
        </section>
        <div class="container__send">
          <button class="button" :disabled="!validateDate" @click="createNewNovelty()">
            crear
          </button>
        </div>
      </div>

      <div :class="['InfoNovelties__information increment']" v-if="showCreate">
        <label>Tipo de novedad</label>
        <select ginput class="InfoNovelties__select" v-model="typeNovedad">
          <option value="">Seleccione una opción</option>
          <option :value="novietis._id" v-for="(novietis, idx) in filteredNovietis" :key="idx">
            {{ novietis.name }}
          </option>
        </select>
        <div v-if="showTime">
          <label class="InfoNovelties__select--one">
            <input type="radio" name="opcion1" value="Reponer" v-model="check" />
            Reponer tiempo
          </label>

          <label class="InfoNovelties__select--two">
            <input type="radio" name="opcion1" value="No reponer" v-model="check" />
            No reponer tiempo
          </label>
        </div>

        <label>Descripción</label>
        <textarea ginput class="InfoNovelties__textarea" v-model="description" placeholder="Escriba una descripción" id="myTextarea" />
        <div class="InfoNovelties__setHour" v-if="replenish">
          <div class="InfoNovelties__setHour--date">
            <div class="container_date">
              <label class="label" for="Desde">Desde</label>
              <input ginput class="date" type="number" v-model="selectedHourInit" @input="validateHour('selectedHourInit', 12)" /> :
              <input ginput class="date" type="number" v-model="selectedMinInit" @input="validateNumber('selectedMinInit')" />
              <select class="options" v-model="optionTime">
                <option value="am">AM</option>
                <option value="pm">PM</option>
              </select>
            </div>
            <div class="container_date">
              <label class="label" for="Hasta">Hasta</label>
              <input ginput class="date" type="number" v-model="selectedHourEnd" @input="validateHour('selectedHourEnd', 12)" /> :
              <input ginput class="date" type="number" v-model="selectedMinEnd" @input="validateNumber('selectedMinEnd')" />
              <select class="options" v-model="optionTimeEnd">
                <option value="am">AM</option>
                <option value="pm">PM</option>
              </select>
            </div>
          </div>
          <div class="InfoNovelties__setHour-container">
            <button class="InfoNovelties__setHour-button" :disabled="!validateInfoTosend" @click="createNewNovelty()">
              crear
            </button>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<style lang="scss">
  .modal-g__container {
    width: 477px;
  }
  .InfoNovelties {
    width: 100%;
    &__wrapper {
      .line {
        height: 1px;
        border-radius: 1px;
        background-color: #e2e2e2;
        margin-bottom: 15px;
        margin-top: 5px;
        width: 130%;
        margin-left: -45px;
      }
      .data__transmision {
        padding-top: 15px;
        .error-message {
          color: $primary_color;
          margin-top: 10px;
        }
        .date__transmision {
          padding: 0 0 0 5px;
          .title__transmision {
            font-family: $primary_font;
            font-size: 16px;
            font-weight: bold;
            color: #000000;
          }
          .break {
            display: flex;
            .input__break {
              width: 60px;
              height: 30px;
              border: 1px solid #e2e2e2;
            }
            :nth-child(1) {
              margin-right: 5px;
            }
            :nth-child(2) {
              margin-left: 5px;
            }
          }
        }
        .container__send {
          text-align: center;
          padding: 20px;
          .button {
            width: 50%;
            background-color: $primary-color;
            border: none;
            padding: 5px;
            color: white;
            border-radius: 5px;
            cursor: pointer;
          }
        }
        .container_date {
          margin-bottom: 10px;
          .options {
            border-radius: 6px;
            border: 1px solid #e2e2e2;
            height: 30px;
            outline: none;
            margin-left: 5px;
          }
        }
        .date {
          width: 55px;
          height: 30px;
          border: 1px solid #e2e2e2;
          margin-right: 1px;
          margin-left: 1px;
        }
        .calendar {
          margin-right: 10px;
          border-radius: 6px;
          border: 1px solid #e2e2e2;
          padding: 1px 8px;
        }
      }
    }
    &__items {
      max-height: 200px;
      overflow: auto;
      margin-top: 15px;
    }
    &__textInfo {
      font-size: 14px;
      font-weight: bold;
      &--model {
        color: #000000;
        font-size: 16px;
        display: flex;
        justify-content: center;
        .name {
          text-transform: capitalize;
        }
        .city {
          margin-left: 30px;
          border: 1px solid $primary-color;
          padding: 1px 10px;
          border-radius: 17px;
          color: $primary-color;
        }
      }
    }
    &__container {
      background: #f5f5f5;
      padding: 5px 10px 0 10px;
      border-radius: 10px;
    }

    &__information {
      padding: 5px;
      label {
        color: black;
        font-weight: bold;
      }
    }
    &__textarea {
      height: 100px;
    }
    &__setHour {
      &--date {
        .container_date {
          .label {
            margin-right: 10px;
            width: 60px;
          }
          .date {
            width: 55px;
            height: 30px;
            border: 1px solid #e2e2e2;
            margin-right: 1px;
            margin-left: 1px;
            margin-bottom: 10px;
          }
          .options {
            border-radius: 6px;
            border: 1px solid #e2e2e2;
            height: 30px;
            outline: none;
            margin-left: 5px;
          }
        }
      }
      &-container {
        text-align: center;
      }
      &-button {
        width: 50%;
        background-color: $primary-color;
        border: none;
        padding: 5px;
        color: white;
        border-radius: 5px;
        cursor: pointer;
        margin: 20px 20px 0 5px;
      }
    }
    &__select {
      &--two {
        margin-left: 25px;
      }
    }
    &__select,
    &__textarea {
      outline: none;
      border: none;
      text-transform: capitalize;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }
    &__avatar {
      margin-right: 5px;
      width: 43px;
      height: 43px;
    }
    &__text {
      display: flex;
    }
    .cardNovelties__button {
      margin: 7px 0;
    }
    &__button {
      margin: 5px;
      color: white;
      width: 30px;
      font-weight: 600;
      border-radius: 5px;
      padding: 2px 0;
      background-color: $primary-color;
      border: solid 1px $primary-color;
    }
    &__item {
      &-input {
        width: 50px;
        height: 30px;
        border: solid 1px #ececec;
      }
    }
  }
  .increment {
    animation: fadeIn 0.5s;
    @keyframes fadeIn {
      0% {
        height: 0px;
      }
      100% {
        height: 100%;
      }
    }
  }
</style>
