import api from "@/api/api";
import setfilter from "@/mixins/setfilter";
export default {
  mixins: [setfilter],
  methods: {
    changeTime(numeroMinutos) {
      const horas = Math.floor(numeroMinutos / 60);
      const minutos = numeroMinutos % 60;
      const horasFormateadas = horas.toString().padStart(2, "0");
      const minutosFormateados = minutos.toString().padStart(2, "0");

      return `${horasFormateadas}H : ${minutosFormateados}M`;
    },
    async getModelsAsignament(query, pagination) {
      try {
        const { data } = await api.get("/novelties");
        return data;
      } catch (error) {
        console.log("error en el mixin", error.message);
        this.showError(error.message);
      }
    },

    async createNovelties(body) {
      try {
        const { data } = await api.post("/novelties", body);
        return data;
      } catch (error) {
        console.log("error en el mixin", error.message);
        this.showError(error.message);
      }
    },
    setQuery() {
      let query = this.$route.query;
      return Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join("&");
    },

    async updateNovelties(body) {
      try {
        const { data } = await api.put("/novelties", body);
        return data;
      } catch (error) {
        console.log("error en el mixin", error.message);
        this.showError(error.message);
      }
    },

    async getLogsDetailNoveties(query) {
      try {
        const { data } = await api.get(`/novelties/logs?${query}`);
        return data;
      } catch (error) {
        console.log("error en el mixin", error.message);
        this.showError(error.message);
      }
    },

    optionsDay() {
      try {
        const [data, hours] = [this.$global.getWorkshiftDates(new Date())[this.$userData.workShift.name], []];
        for (let i = data.from; i <= data.to; i++) {
          let [hourplusone, date] = ["", new Date(i)];
          if (!hours.length) hourplusone = new Date(date);
          else hourplusone = new Date(date.setMinutes(date.getMinutes() + 1));
          i = hourplusone;
          hours.push({
            date: hourplusone,
            label: `${date.getHours() % 12 < 10 ? "0" + (date.getHours() % 12) : date.getHours() % 12}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()} ${
              date.getHours() >= 12 ? "PM" : "AM"
            }`,
          });
        }
        return hours;
      } catch (error) {
        console.log("Error el monitor no tiene turno", error.message);
        this.showError("Error el monitor no tiene turno");
      }
    },

    getTipesNovedades(novedad) {
      return this.typesNovietis?.filter((item) => item._id.$oid === novedad)[0]?.name;
    },

    async getNoveltiesAdmin(query) {
      try {
        const { data } = await api.get(`/novelties/getNovelties?${query}`);
        if (!data.error) return data.novelties;
        else throw new Error(data.error);
      } catch (error) {
        console.log("Ocurrio un error al traer las novedades", error.message);
        this.showError("Ocurrio un error al traer las novedades");
      }
    },

    getTyopeNovelties(id) {
      return this.typesNovietis.find((item) => {
        if (item._id.$oid === id) return item;
      });
    },

    async getNoveltiesDetail(query) {
      try {
        const { data } = await api.get(`/novelties/detail?${query}`);
        if (!data.error) return data.novelties;
        else throw new Error(data.error);
      } catch (error) {
        console.log("Ocurrio un error al traer las novedades", error.message);
        this.showError("Ocurrio un error al traer las novedades");
      }
    },

    showError(error) {
      this.$alerts.dataSendError({ message: error });
    },

    showSuccess(message) {
      this.$alerts.dataSendSuccess({ message });
    },

    validButtonActiveToAdd(day) {
      return new Date(day).getDay() >= new Date().getDay();
    },
  },

  data() {
    return {
      typesNovietis: [
        { name: "Incapacidad", isEnable: true, _id: { $oid: "628aca008e84d13c1f2dee94" } },
        { name: "Calamidad", isEnable: true, _id: { $oid: "629aca008e84d13c1f2dee95" } },
        { name: "Tiempo de conexión", isEnable: true, _id: { $oid: "627aca008e84d13c1f2dee94" } },
        { name: "Reunión administrativa", isEnable: true, _id: { $oid: "627aca008e84d13c1f2dee95" } },
        { name: "Servicio técnico", isEnable: true, _id: { $oid: "627aca478e84d13c1f2dee96" } },
        { name: "Falla internet", isEnable: true, _id: { $oid: "627aca728e84d13c1f2dee97" } },
        { name: "Falla servidores", isEnable: true, _id: { $oid: "627aca9a8e84d13c1f2dee98" } },
        { name: "Feedback", isEnable: true, _id: { $oid: "627acaaf8e84d13c1f2dee99" } },
        { name: "Break", isEnable: true, _id: { $oid: "628cee2c87c76adae90cf84d" } },
        { name: "Otro", isEnable: true, _id: { $oid: "627acac58e84d13c1f2dee9a" } },
      ],
      list: [
        { name: "Novedad", time: "30 Min" },
        { name: "Novedad 2", time: "50 Min" },
      ],
      turns: [
        {
          value: "late",
          name: "Tarde",
        },
        {
          value: "morning",
          name: "Mañana",
        },
        {
          value: "night",
          name: "Noche",
        },
      ],
      turn: this.$global.dictionary.workShifts_es_auto,
      statusLists: {
        approved: "Aprobado",
        rejected: "Rechazado",
        pending: "Pendiente",
      },
      tableLists: ["MODELO", "HABITACIÓN", "HORARIO", "T.BREAK", "T.REPOSICION", "T.RECHAZADO", "TIEMPO TOTAL"],
      titlesTable: ["MONITOR", "HABITACION", "HORARIO", "VALIDADO POR", "FECHA", "ESTADO"],
      titlesModalDataInfo: ["Fechas", "Habitación", "Turno fijo", "T. Break", "T. Reposicion", "T. Total"],
      HeaderListNovelties: ["modelo", "fecha", "horario", "monitor", "novedad", "sugerencia", "tiempo"],
    };
  },
};
